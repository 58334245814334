<template>
  <!-- 新的首页 -->
  <div>
    <header class="banner">
      <h1>惠企政策精准推送，企业服务智能升级</h1>
      <search></search>
      <latest-policy></latest-policy>
    </header>
    <div class="def-container1">
      <div class="row1">
        <div class="title_01">
          <div class="tit_txt icon_tit">
            <img src="~@/assets/deyangImg/fw_icon.png" /><span>特色服务</span>
          </div>
        </div>
        <div class="row_cont">
          <div class="r_left">
            <div v-if="isMore == 'index'">
              <!-- 最新资讯 -->
              <!-- <div>
                <div class="subtitleDiv">
                  <span>最新资讯</span>
                  <span style="cursor: pointer" @click="isMore = 'zxList'"
                    >更多</span
                  >
                </div>
                <el-carousel
                  height="150px"
                  indicator-position="none"
                  arrow="never"
                >
                  <el-carousel-item
                    v-for="(item, index) in imgData"
                    :key="index"
                  >
                    <div class="carouselItwm" @click="isMore = 'zxDetail'">
                      <img width="100%" height="120px" :src="item.src" alt="" />
                      <div
                        style="font-size: 12px; width: 100%; text-align: center"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div> -->
              <!-- 政策排名 -->
              <div>
                <p style="amrgin: 10px 0; font-weight: 600">政策排名</p>
                <el-table :data="policyRankData" style="width: 100%">
                  <el-table-column type="index" label="排名" align="left">
                  </el-table-column>
                  <el-table-column
                    prop="deptName"
                    show-overflow-tooltip
                    label="部门名称"
                    align="center"
                  >
                    <template slot-scope="{ row }">
                      <span style="font-weight: 600">{{ row.deptName }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    width="60px"
                    prop="countNum"
                    label="政策数量"
                    align="right"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div v-if="isMore == 'zxList'">
              <div class="subtitleDiv">
                <span>资讯列表</span>
                <span style="cursor: pointer" @click="isMore = 'index'"
                  >返回</span
                >
              </div>
              <el-table :data="zxList" :show-header="false" style="width: 100%">
                <el-table-column prop="name" label="资讯标题" align="left">
                </el-table-column>
                <el-table-column prop="date" label="日期" align="right">
                </el-table-column>
              </el-table>
              <div style="text-align: center">
                <el-pagination
                  background
                  @current-change="handleCurrentChange"
                  :current-page="zxPageInfo.pageNum"
                  :page-size="zxPageInfo.pageSize"
                  layout="total, prev, pager, next, jumper"
                  :total="zxPageInfo.total"
                >
                </el-pagination>
              </div>
            </div>
            <div v-if="isMore == 'zxDetail'">
              <div class="subtitleDiv">
                <span>资讯详情</span>
                <span style="cursor: pointer" @click="isMore = 'index'"
                  >返回</span
                >
              </div>
              <img
                src="@/assets/images/Bananer.png"
                width="100%"
                height="150px"
                alt=""
              />
              <div style="margin: 10px 0; text-indent: 2rem">
                资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情资讯详情
              </div>
            </div>
          </div>
          <div class="r_right">
            <div class="item item1">
              <h2>
                系统向您推荐了<span>{{ userInfo ? totleSystem.qb : "?" }}</span
                >条政策
              </h2>
              <p>
                根据您企业的属性，系统自动向您推荐相关的政策项目，如果没有向您推荐，请点击&lt;&lt;
                <span
                  style="
                    cursor: pointer;
                    text-decoration: underline;
                    color: yellow;
                  "
                  @click="toOtherPage('userCenter')"
                  >完善企业信息</span
                >
                &gt;&gt;，并准确填写企业的各项指标。
              </p>
              <a href="javascript:void(0)" @click="toOtherPage('system')"
                >查看</a
              >
            </div>
            <div class="item item3">
              <h2>
                系统向您推荐了<span>{{ userInfo ? totalMsjx.qb : "?" }}</span
                >条免申即享政策
              </h2>
              <p>系统向您推荐的免申即享政策，请尽快进入查看页面完善信息</p>
              <a href="javascript:void(0)" @click="toOtherPage('enjoy')"
                >查看</a
              >
            </div>
            <div class="item item2">
              <h2>
                部门向您推荐了<span>{{ userInfo ? totleDept.qb : "?" }}</span
                >条政策
              </h2>
              <p>政策发布部门向您推荐的政策，为理通过率极高，请尽快办理。</p>
              <a href="javascript:void(0)" @click="toOtherPage('dept')">查看</a>
            </div>
          </div>
        </div>
        <div class="row_cont swzq" @click="toSwzq">
          <div class="item"></div>
        </div>
      </div>
      <div class="row2">
        <div class="title_01">
          <div class="tit_txt icon_tit">
            <img src="~@/assets/deyangImg/rm_icon.png" /><span>热门服务</span>
          </div>
        </div>
        <div class="row_cont rec_fiveCel">
          <div class="item item1">
            <a href="javascript:void(0)" @click="topage('policyLibrary')"
              >政策检索</a
            >
          </div>
          <div class="item item2">
            <a href="javascript:void(0)" @click="topage('showPolicy')"
              >智能匹配</a
            >
          </div>
          <div class="item item3">
            <a href="javascript:void(0)" @click="topage('policy')">奖励计算</a>
          </div>
          <!-- <div class="item item4">
            <a href="javascript:void(0)" @click="topage('policyProgram')"
              >项目申报</a
            >
          </div> -->
          <div class="item item4">
            <a href="javascript:void(0)" @click="topage('policyEnterprises')"
              >项目申报</a
            >
          </div>
          <!-- <div class="item item4">
            <a href="javascript:void(0)" @click="topage('policyEnterprises')"
              >诉求</a
            >
          </div> -->
          <div class="item item5">
            <a href="javascript:void(0)" @click="topage('policyAnalysis')"
              >政策分析</a
            >
          </div>
          <!-- <div class="item item5">
            <a href="javascript:void(0)" @click="topage('policyEnterprises')"
              >惠企政策</a
            >
          </div> -->
        </div>
        <!-- <div class="fixedPdf" @click="openPdf">
          <img src="@/assets/images/home/policy.svg" alt="" />
          <p>德阳市“废改留立”政策汇总</p>
        </div> -->
      </div>
    </div>
    <el-dialog
      style="z-index: 99999"
      title="用户中心账户设置"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <div class="dialogContent">
        <company-base-info
          :isfromLogin="true"
          @closeDialog="closeDialog"
        ></company-base-info>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Search from "./search.vue";
import { mapGetters } from "vuex";
import LatestPolicy from "./LatestPolicy.vue";
import CompanyBaseInfo from "../workplace/components/setting/companyBaseInfo.vue";
export default {
  components: { Search, LatestPolicy, CompanyBaseInfo },
  props: {},
  data() {
    return {
      inputKeyWord: "",
      typeIndex: 0, // 0按关键字 1按企业
      totleSystem: {}, //系统推荐政策条数对象
      totleDept: {}, //部门推荐政策条数对象
      totalMsjx: {},
      isFromLogin: false, //是否由登录跳转过来
      dialogVisible: false,
      policyRankData: [],
      zxList: [
        {
          name: "资讯标题",
          date: "2023-02-09",
        },
        {
          name: "资讯标题",
          date: "2023-02-09",
        },
        {
          name: "资讯标题",
          date: "2023-02-09",
        },
        {
          name: "资讯标题",
          date: "2023-02-09",
        },
        {
          name: "资讯标题",
          date: "2023-02-09",
        },
      ],
      imgData: [
        {
          src: require("@/assets/images/Bananer.png"),
          title: "资讯标题2",
        },
        {
          src: require("@/assets/images/background.png"),
          title: "资讯标题1",
        },
        {
          src: require("@/assets/images/banner.jpg"),
          title: "资讯标题3",
        },
      ],
      isMore: "index",
      zxPageInfo: {
        pageNum: 1,
        pageSize: 4,
        total: 5,
      },
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
    }),
  },
  created() {
    this.getPolicyRankData();
    this.getZxList();
    this.getPolicyBySystem();
    this.getDeptPolicy();
    this.getmsjxTotal();
  },
  mounted() {
    if (sessionStorage.getItem("isFromLogin") == "true") {
      this.dialogVisible = true;
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.fullPath == "/login") {
      sessionStorage.setItem("isFromLogin", true);
    } else {
      sessionStorage.setItem("isFromLogin", false);
    }
    next();
  },
  methods: {
    openPdf(){
      window.open('./废改留立汇总.pdf','_blank')
    },
    // 资讯列表分页
    handleCurrentChange(val) {
      this.zxPageInfo.pageNum = val;
      this.getZxList();
    },
    // 获取资讯列表
    getZxList() {
      let params = {
        pageNum: 1,
        pageSize: 10,
      };
      let url = "";
      this.$httpApi.post(url, params).then((res) => {
        if (res.code == 200) {
          this.data = res.data;
        }
      });
    },
    //获取政策排名
    getPolicyRankData() {
      let that = this;
      let url = "/dev-api/policy/count/list?pageSize=10&pageNum=1";
      that.$httpApi.get(url, {}).then((res) => {
        if (res.code == 200) {
          that.policyRankData = res.rows;
        }
      });
    },
    toSwzq() {
      let href = "https://sichuan.chinatax.gov.cn/col/col280/index.html";
      window.open(href, "_blank");
    },
    closeDialog(param) {
      this.dialogVisible = param;
    },
    // 有登录权限的
    topageIsLogin(name) {
      switch (name) {
        case "showPolicy":
          if (this.userInfo) {
            this.$router.push({
              name: "showPolicy",
              query: { linkType: true },
            });
          } else {
            this.$router.push({ path: "/login" });
          }
          break;
        case "policyAnalysis":
          let { href } = this.$router.resolve({
            path: "/policyAnalysis",
          });
          window.open(href, "_blank");
          break;
        case "policyProgram":
          if (this.userInfo) {
            this.$router.push({ name: name });
          } else {
            this.$router.push({ path: "/login" });
          }
          break;
        // case "appeal":
        //   if (this.userInfo) {
        //     this.$router.push({ name: name });
        //   } else {
        //     this.$router.push({ path: "/login" });
        //   }
        //   break;
        case "policyEnterprises":
          if (this.userInfo) {
            this.$router.push({ name: name });
          } else {
            this.$router.push({ path: "/login" });
          }
          break;
        default:
          this.$router.push({ name: name });
          break;
      }
    },
    // 没有登录权限的
    topage(name) {
      switch (name) {
        case "showPolicy":
          this.$router.push({
            name: "showPolicy",
            query: { linkType: true },
          });
          break;
        case "policyAnalysis":
          let { href } = this.$router.resolve({
            path: "/policyAnalysis",
          });
          window.open(href, "_blank");
          break;
        default:
          this.$router.push({ name: name });
          break;
      }
    },
    toApplyGuide() {
      this.$router.push({ name: "applyGuide" });
    },
    toOtherPage(param) {
      let params = param;
      if (this.userInfo) {
        switch (params) {
          case "system":
          case "dept":
          case "enjoy":
            sessionStorage.setItem("matchActiveName", param);
            this.$router.push({
              name: "policyMatchReport",
            });
            break;
          case "userCenter":
            this.$router.push("/workplace/setting");
            break;
          // case "enjoyWithoutApply":
          //   this.$router.push('/enjoyWithoutApply')
          //   break;
          default:
            break;
        }
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    // 系统推荐
    getPolicyBySystem() {
      let that = this;
      let url = "/dev-api/policy/evaluation/recommend";
      let params = {
        shxydm: this.userInfo.tyshxydm,
        pageNum: 1,
        pageSize: 500,
      };
      this.$httpApi
        .get(url, params)
        .then((res) => {
          if (res.code == 200) {
            let numObj = {
              dsj: res.data.dsj,
              gj: res.data.gj,
              sj: res.data.sj,
              qb: res.data.list.total,
            };
            that.totleSystem = numObj;
          }
        })
        .catch((err) => {
          console.log("getPolicyBySystem error:", err);
        });
    },
    // 部门推荐
    getDeptPolicy() {
      let url = "/dev-api/policy/evaluation/deptRecommend";
      let param = {
        tyshxydm: this.userInfo.tyshxydm,
        pageNum: 1,
        pageSize: 10,
      };
      this.$httpApi
        .post(url, param)
        .then((res) => {
          let numObj = {
            dsj: res.data.dsj,
            gj: res.data.gj,
            sj: res.data.sj,
            qb: res.data.pageInfo.total,
          };
          this.totleDept = numObj;
        })
        .catch();
    },
    getmsjxTotal() {
      let params = {
        pageSize: 1,
        pageNum: 6,
        tyshxydm: this.userInfo.tyshxydm,
      };
      let url = "/dev-api/system/apply/querySqAvoidApplyList";
      this.$httpApi.post(url, params).then((res) => {
        if (res.code == 200) {
          let numObj = {
            dsj: res.data.dsj,
            gj: res.data.gj,
            sj: res.data.sj,
            qb: res.data.pageInfo.total,
          };
          this.totalMsjx = numObj;
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
@import "./style.scss";
.rec_fiveCel {
  display: flex;
  justify-content: space-between;
}
.dialogContent {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
}
::v-deep .el-dialog__wrapper {
  z-index: 99999 !important;
}
.swzq {
  width: 100%;
  border-radius: 12px;
  margin-top: 20px;
  cursor: pointer;
  .item {
    height: 145px;
    background: url("~@/assets/deyangImg/swzq.jpg") center center no-repeat;
    background-size: 100% 100%;
    transition: background-size 0.3s;
    &:hover {
      background-size: 110% 110%;
    }
  }
}
::v-deep .el-table__expanded-cell {
  background-color: transparent !important;
}

::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: transparent !important;
  color: #fff;
  border-bottom: none !important;
  padding: 5px 0 !important;
}
::v-deep .el-table {
  background-color: transparent !important;
  color: #fff;
  border-bottom: none !important;
}
::v-deep .el-table::before {
  background-color: transparent !important;
}
::v-deep .el-table .cell {
  padding-right: 0 !important;
}
::v-deep .el-table .cell,
.el-table--border td:first-child .cell,
.el-table--border th:first-child .cell {
  padding-left: 0 !important;
}
::v-deep .el-table__empty-text {
  color: #fff !important;
}
::v-deep .el-pagination {
  color: #fff !important;
}
::v-deep .el-pagination__total {
  color: #fff !important;
}
::v-deep .el-pagination__jump {
  color: #fff !important;
}
::v-deep .el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background-color: transparent !important;
  color: #fff !important;
}
// ::v-deep .el-pagination.is-background .el-pager li {
//   background-color: transparent !important;
// }
// :v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
//   background-color: #729be9 !important;
// }
.carouselItwm {
  width: 100%;
  height: 140px;
}
.subtitleDiv {
  margin: 10px 0;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.fixedPdf {
  cursor: pointer;
  width: 100px;
  height: 120px;
  position: fixed;
  left: 50px;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    // width: 60px;
    // height: 50px;
  }
  p {
    text-align: center;
    color: #000;
    margin-top: 10px;
    &:hover {
      color: #369aff;
    }
  }
}
</style>

<template>
  <div>
    <div class="container">
      <div class="top">最新政策</div>
      <!-- <vue-seamless-scroll
        :data="policyData"
        class="seamless-warp"
        :class-option="optionSingleHeightTime"
      >
        <ul class="item">
          <li
            v-for="item in policyData"
            :key="item.id"
            @click="toPolicyDetail(item)"
            :title="item.name"
          >
            <span class="title">{{item.name}}</span>
            <span class="date">{{item.publictime.split(' ')[0]}}</span>
          </li>
        </ul>
      </vue-seamless-scroll> -->
      <el-carousel
        height="40px"
        direction="vertical"
        autoplay
        :interval="3000"
        indicator-position="none"
      >
        <el-carousel-item v-for="item in policyData1" :key="item.id">
          <div class="policyItem" @click="toPolicyDetail(item)">
            <span class="title">{{ item.name }}</span>
            <span class="date">{{ item.publictime.split(" ")[0] }}</span>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="divider"></div>
      <div class="carousel2">
        <el-carousel
          height="40px"
          direction="vertical"
          autoplay
          :interval="3000"
          indicator-position="none"
        >
          <el-carousel-item v-for="item in policyData2" :key="item.id">
            <div class="policyItem" @click="toPolicyDetail(item)">
              <span class="title">{{ item.name }}</span>
              <span class="date">{{ item.publictime.split(" ")[0] }}</span>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="allBtn" @click="showMOre">
        {{ showMoreTxt }}
        <i v-if="showMoreFlag" class="el-icon-arrow-down"></i>
        <i v-else class="el-icon-arrow-up"></i>
      </div>
      <div class="allPolicy" v-show="showMoreFlag">
        <ul>
          <li v-for="item in policyData1" :key="item.id" :title="item.name" @click="toPolicyDetail(item)">
            <span class="title">{{ item.name }}</span>
            <span class="date">{{ item.publictime.split(" ")[0] }}</span>
          </li>
        </ul>
        <ul>
          <li v-for="item in policyData2" :key="item.id" :title="item.name" @click="toPolicyDetail(item)">
            <span class="title">{{ item.name }}</span>
            <span class="date">{{ item.publictime.split(" ")[0] }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      showMoreFlag: false, //展示更多最新政策
      showMoreTxt: "全部",
      policyData: [],
      policyData1: [],
      policyData2: [],
    };
  },
  created() {
    this.getPolicy();
  },
  computed: {
    classOption() {
      return {
        direction: 0,
        // step:0.1,
        singleHeight: 26, //单行停顿
      };
    },
    optionSingleHeightTime() {
      return {
        singleHeight: 12,
        waitTime: 3000,
      };
    },
  },
  mounted() {},
  methods: {
    showMOre() {
      this.showMoreFlag = !this.showMoreFlag;
      if (this.showMoreFlag) {
        this.showMoreTxt = "收起";
      } else {
        this.showMoreTxt = "全部";
      }
    },
    getPolicy() {
      let url = "/elastic/search/sq-policy";
      let params = {
        pageNum: 1,
        pageSize: 20,
        keyWords: "",
        areaValue: [],
        enterpriseAttributesValue: [],
        enterpriseSizeValue: [],
        growthCycleValue: [],
        sysCategoryValue: [],
        policyClassificationValue: [],
        deptName: [],
        supportWayValue: [],
        sortBy: "1",
      };
      this.$httpApi.post(url, params).then((res) => {
        this.policyData = res.rows.slice(0, 20);
        this.policyData1 = res.rows.slice(0, 10);
        this.policyData2 = res.rows.slice(10, 20);
      });
    },
    toPolicyDetail(row) {
      let data = JSON.stringify(row);
      sessionStorage.setItem("policyDetailData", data);
      this.$router.push({
        name: "showPolicyDetail",
      });
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep {
  .el-carousel__item {
    color: #fff !important;
    font-size: 16px !important;
  }
  .el-carousel__item:hover {
    text-decoration: underline;
  }
  .el-carousel {
    left: 106px;
    top: -31px;
  }
}
.carousel2 {
  margin-left: 520px;
  margin-top: -40px;
  ::v-deep .el-carousel {
    right: 0 !important;
    top: -31px !important;
  }
}
.policyItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 50px;
  .title {
    font-size: 16px;
    width: 340px;
    display: inline-block;
    // width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .date {
    width: 90px;
    margin-left: 20px;
  }
}
.seamless-warp {
  cursor: pointer;
  height: 80px;
  overflow: hidden;
}
.container {
  width: 1200px;
  height: 40px;
  // background-color: rgba($color: #000000, $alpha: 0.1);
  background-color: rgba($color: #fff, $alpha: 0.1);
  position: absolute;
  // right: 10px;
  // top: calc(50% - 155px);
  bottom: 15px;
  left: calc(50% - 600px);
  .top {
    color: #fff;
    width: 100px;
    height: 40px;
    background-color: #fd600f;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    // padding-left: 10px;
    // margin-bottom: 10px;
  }
  .divider {
    width: 1px;
    height: 40px;
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 0;
    left: 630px;
  }
  .allBtn {
    cursor: pointer;
    color: #fff;
    z-index: 99998;
    position: absolute;
    background: #1989f8;
    top: 9px;
    right: 12px;
    font-size: 13px;
    padding: 2px;
  }
  .allPolicy {
    width: 1100px;
    height: 285px;
    overflow-y: auto;
    padding: 0 40px;
    border-radius: 5px;
    background: rgba(14, 151, 255, 0.95);
    position: absolute;
    top: -135px;
    left: 100px;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    &::-webkit-scrollbar {
      display: none;
    }
    ul {
      width: 50%;
      height: 250px;
      color: #fff;
      padding: 0 10px 10px 10px;
      margin-bottom: 20px;
      li {
        font-size: 16px;
        cursor: pointer;
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
        &:hover {
          text-decoration: underline;
        }
        .title {
          display: inline-block;
          // width: 80%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .date {
          min-width: 25%;
          margin-left: 5px;
        }
      }
    }
  }
}
</style>

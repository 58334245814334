<template>
  <div class="flex-column-between home-search">
    <div class="home-topL-search">
      <div class="input-search-bar">
        <el-input
          class="input-search"
          style="font-size: 16px;"
          v-model.trim="inputKeyWord"
          @keyup.enter.native="toSearch"
          placeholder="请输入事项名称"
        >
        </el-input>
        <button class="input-search-btn" @click="toSearch"
          >搜索
        </button>
      </div>
      <!-- 热搜词 -->
      <div
        class="input-tips flex-row flex-start pointer"
        v-show="typeIndex == 0"
      ></div>
    </div>
  </div>
</template>

<script>
import { FormSelect, FormSelectAutoComplete } from "@/components/form/index.js";

const HOME_TITLE = require("@/assets/images/banner_text.png");

export default {
  components: {
    // FormSelect, FormSelectAutoComplete,
  },
  props: {
    typeList: {
      type: Array,
      default: function () {
        return [
          {
            name: "按关键字",
            placeholder: "政策/项目/通知/公示",
            searchName: "搜 索",
            value: "sortByKeyWord",
          },
          {
            name: "按企业",
            placeholder: "企业名称，进行政策匹配",
            searchName: "匹 配",
            value: "sortByCompany",
          },
        ];
      },
    },
  },
  data() {
    return {
      flag: false,
      searchType: "sortByKeyWord", // 搜索类型{'sortByKeyWord':按关键字, 'sortByCompany':按企业}
      tyshxydm: "", // 企业统一社会信用代码
      companyData: [], // 企业列表
      inputKeyWord: "",
      searchSelect: "按关键字",
      typeIndex: 0, // 0按关键字 1按企业
      showType: {},
      tipsList: [], // 热搜词
      enterpriseData: [],
      selectEnterprise: null, // 选择的社会信用代码的item
      selectValue: "1",
      options: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "2",
          label: "政策库",
        },
        {
          value: "3",
          label: "企业办事库",
        },
        // {
        //   value: "4",
        //   label: "一件事一次办",
        // },
        // {
        //   value: "5",
        //   label: "政策项目",
        // },
        {
          value: "6",
          label: "通知公告",
        },
      ],
    };
  },
  computed: {
    cpu_homeTitleSrc() {
      return HOME_TITLE;
    },
  },
  created() {
    this.showType = this.typeList[0];
    this.getHotWords();
    if (sessionStorage.getItem("menuIndex")) {
      this.selectValue = sessionStorage.getItem("menuIndex");
    }
  },
  mounted() {},
  methods: {
    /**
     * @description: 联想企业
     * @param {String} keyword 搜索关键字
     * @param {Function} cb 回调函数
     */
    searchComponay(keyword, cb) {
      let url = "/dev-api/elasticMgr/getEnterpriseDatas";
      let params = {
        pageNum: 1,
        pageSize: 10,
        keyWords: keyword,
      };
      this.companyData = [];
      this.$httpApi
        .get(url, params)
        .then((res) => {
          console.log("queryCompany:", res);
          if (res.code == 200) {
            this.companyData = (res.data || []).map((cv) => {
              return {
                ...cv,
                label: cv.name.replace(/<[^<>]+>/g, ""),
              };
            });
            cb(res.data || []);
          } else {
            cb([]);
          }
        })
        .catch((err) => {
          console.log("queryCompany error:", err);
          cb([]);
        });
    },
    getHotWords() {
      this.$httpApi
        .get("/dev-api/system/hotWords", {})
        .then((res) => {
          if (res.code == 200) {
            let list = res.rows;
            console.log("热门词:", list);
            if (list.length === 0) {
              return;
            }
            if (list.length <= 3) {
              this.tipsList = list || [];
            } else {
              this.tipsList = [list[0], list[1], list[2]];
            }
          }
        })
        .catch((err) => {});
    },
    querySearchAsync(queryString, cb) {
      this.queryStringEnterprise(queryString, cb);
    },
    queryStringEnterprise(queryString, cb) {
      this.enterpriseData = [];
      let url = `/dev-api/cfgpermitconfig/query/data/find/cfg_permitinfo_andzchx_list`;
      let params = {
        keyword: queryString,
        itemtype: "2",
        release: "1",
        ["PAGE_CUR"]: "1",
        ["PAGE_ROWS"]: "",
      };
      this.$httpApi
        .post(url, params)
        .then((res) => {
          let result = [];
          let list = res.data;
          this.enterpriseData = list;
          for (const i in list) {
            let name = list[i].dept_name;
            //   let n1 = name.replace(/<\/?.+?>/g, "");
            //   let n2 = n1.replace(/ /g, "");
            // n2 += `(${list[i].shxydm})`;
            list[i].title = name;
            result.push({ value: name });
          }
          if (result.length > 0) {
            cb(result);
          } else {
            cb([{ value: "暂无数据" }]);
          }
        })
        .catch((err) => {});
    },
    // 选择autocomplete下拉选项
    handleSelect(item) {
      this.inputKeyWord = item.value;
      if (this.typeIndex == 1) {
        for (const i in this.enterpriseData) {
          const element = this.enterpriseData[i];
          if (item.value === element.title) {
            console.log("选择的", element);
            this.selectEnterprise = element;
          }
        }
      }
      this.gotoSearchResult();
    },
    /* 开始搜索 */
    toSearch() {
      // sessionStorage.setItem(
      //     "titleTypeBack",
      //     sessionStorage.getItem("titleType")
      //   );
      // //sessionStorage.setItem("titleType", "1");
      if (this.typeIndex == 1) {
        this.queryStringEnterprise(this.inputKeyWord, () => {});
      } else {
        this.gotoSearchResult();
      }
    },
    /* 热搜词转跳 */
    tipTo(e) {
      this.inputKeyWord = e.word;
      this.gotoSearchResult();
    },
    gotoSearchResult() {
      // if (this.inputKeyWord.trim().length == 0) {
      //   this.$message({
      //     type: "warning",
      //     message: "请输入您要搜索的内容",
      //   });
      //   return;
      // }
      if (this.typeIndex == 0) {
        let params = {
          keyWords: this.inputKeyWord,
          type: this.typeList[this.typeIndex].value,
        };
        sessionStorage.setItem("searchData", JSON.stringify(params));
        this.$router.push({
          name: "searchList",
          params: { keyWords: this.inputKeyWord },
        });
        this.inputKeyWord = "";
      } else {
        if (this.selectEnterprise == null || this.selectEnterprise.id == null) {
          this.$message({
            type: "error",
            message: "企业信息错误, 请重新输入",
          });
          return;
        }
        let params = {
          keyWords: this.inputKeyWord,
          type: this.typeList[this.typeIndex].value,
          selectEnterprise: this.selectEnterprise,
        };
        sessionStorage.setItem("searchCompanyData", JSON.stringify(params));
        let { href } = this.$router.resolve({
          // path: "/policyMatch",
          name: "searchCompany",
          query: params,
        });
        window.open(href);
        this.inputKeyWord = "";
      }
    },
    selectChange(val) {
      sessionStorage.setItem("menuIndex", val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";
::v-deep .el-input__inner {
  height: 100%;
  border:none !important;
}
.home-search {
  padding: 15px;
}
.home-topL-title {
  align-self: flex-start;
  margin-bottom: 10px;
}
.home-topL-search {
  width: 100%;
  height: 100px;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  left: 0;

  .input-search-bar {
    width: 604px;
    height: 58px;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    margin: 0 auto;
    padding: 4px 105px 4px 5px;
    position: relative;
    /* 输入框内容 */
    .input-search {
      width: 100%;
      height: 100%;
      background: #fff;
      padding: 0 16px;
      font-size: 16px;
      .el-input__inner {
        border: none !important;
        border-radius: 0 !important;
        background-color: #ffffff !important;
      }
      input::-webkit-input-placeholder {
        color: #999999;
      }
    }
    /* 输入框按钮 */
    .input-search-btn {
      width: 100px;
      height: 50px;
      position: absolute;
      right: 5px;
      top: 4px;
      background: #e95151;
      text-align: center;
      line-height: 50px;
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      border: 0;
    }
  }
  .input-tips {
    .input-tips-item {
      font-size: $font-size16;
      height: $font-size16;
      line-height: $font-size16;
      color: #fff;
      padding: 0 10px;
      // color: $color-fff;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
